import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form, Row, Col, Dropdown } from "react-bootstrap";
import * as consts from "../../consts/Consts";
import { createHeaders, fetchGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function EditStudent({ show, handleClose, data }) {
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		full_name: "",
		email: "",
		phone: "+994",
		birth_day: "",
		password: "",
		groups: [],
	});

	const resetFormData = () => {
		setFormData({
			full_name: "",
			email: "",
			phone: "+994",
			birth_day: "",
			password: "",
			groups: [],
		});
		setErrors({});
	};

	const [errors, setErrors] = useState({});

	const getGroupPrefix = (name) => {
		const [prefix] = name.split("-");
		return prefix;
	};

	const getDropdownLabel = () => {
		if (!Array.isArray(groups) || groups.length === 0) {
			return "Qruplar mövcud deyil";
		}

		if (formData.groups.length === 0) {
			return "Siyahıdan seçin";
		}

		const selectedPrefixes = new Set(
			formData.groups
				.map((group) => {
					const groupData = groups.find((g) => g.id.toString() === group.group_id);
					return groupData ? getGroupPrefix(groupData.name) : null;
				})
				.filter((prefix) => prefix)
		);

		return Array.from(selectedPrefixes).join(", ");
	};

	const formatPhoneNumberCompact = (value) => {
		const cleanValue = value.replace(/\D/g, "");

		if (cleanValue.startsWith("994")) {
			return `+${cleanValue}`;
		} else {
			return `+994${cleanValue.slice(3)}`;
		}
	};

	const cleanPhoneNumber = (value) => {
		return value.replace(/\D/g, "");
	};

	const handleBlur = (e) => {
		const { name, value } = e.target;
		if (name === "phone" && value === "") {
			setFormData((prevData) => ({
				...prevData,
				[name]: "+994 ",
			}));
		}
	};

	const handleChange = (e) => {
		const { name, value, checked } = e.target;

		if (name === "group") {
			setFormData((prevData) => {
				const newGroup = checked
					? [...prevData.groups, { group_id: value }]
					: prevData.groups.filter((item) => item.group_id !== value);
				return { ...prevData, groups: newGroup };
			});
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		if (value.trim() && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.full_name.trim()) {
			newErrors.full_name = "Ad və soyad daxil edin";
		} else if (formData.full_name.length > 64) {
			newErrors.full_name = "Ad və soyad 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.email) {
			newErrors.email = "Email daxil edin.";
		} else {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(formData.email)) {
				newErrors.email = "Düzgün email daxil edin.";
			}
		}

		if (!formData.phone || formData.phone.length < 13) {
			newErrors.phone = "Telefon nömrəsini daxil edin.";
		}

		if (!formData.birth_day) {
			newErrors.birth_day = "Doğum tarixini daxil edin.";
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	const [groups, setGroups] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchGroups(null, 100); // Pass perPage as 100
				setGroups(data.items); // Ensure data.items matches the structure you need
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const parseDate = (dateString) => {
		const [day, month, year] = dateString.split(".");
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	};

	const handleGetData = async () => {
		try {
			const headers = createHeaders();
			const response = await fetch(
				`${consts.API_URL}/admin/student/${data.id}`,
				{
					headers,
				}
			);
			if (!response.ok) {
				throw new Error("Ошибка при получении данных");
			}
			const result = await response.json();
			if (result.data && typeof result.data === "object") {
				const { full_name, email, phone, birth_day, groups } = result.data;
				const formattedDate = birth_day ? parseDate(birth_day) : "";

				setFormData({
					full_name: full_name || "",
					email: email || "",
					password: "",
					phone: phone || "+994",
					birth_day: formattedDate,
					groups:
						groups?.map((group) => ({
							group_id: group.id.toString(),
							name: group.name,
						})) || [],
				});
			} else {
				// console.error("Ожидался объект, но получен:", result.data);
			}
		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};

	useEffect(() => {
		if (show && data?.id) {
			handleGetData();
		}
	}, [show, data?.id]);

	const handleSubmit = async () => {
		if (validateForm()) {
			const headers = createHeaders();

			const cleanedPhoneNumber = cleanPhoneNumber(formData.phone);
			const formattedPhoneCompact =
				formatPhoneNumberCompact(cleanedPhoneNumber);

			const dataToSend = {
				...formData,
				phone: formattedPhoneCompact,
			};

			if (!dataToSend.password) {
				delete dataToSend.password;
			}

			try {
				const response = await fetch(
					`${consts.API_URL}/admin/student/${data.id}`,
					{
						method: "PUT",
						headers: headers,
						body: JSON.stringify(dataToSend),
					}
				);

				const responseData = await response.json();

				if (response.ok) {
					// console.log("Student updated successfully:", responseData);
					handleClose();
					window.location.reload();
				} else {
					if (
						responseData.errors &&
						responseData.errors.email &&
						responseData.errors.email.includes(
							"The email has already been taken."
						)
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							email: "Bu email artıq istifadə olunur.",
						}));
					} else {
					}
				}
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			}
		}
	};

	return (
		<Modal show={show} onHide={handleCloseModal}>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<Form>
					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formInput1">
								<Form.Label>Ad və Soyad</Form.Label>
								<Form.Control
									type="text"
									name="full_name"
									value={formData.full_name}
									onChange={handleChange}
									placeholder="Ad və Soyad daxil edin"
									isInvalid={!!errors.full_name}
									className={errors.full_name ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.full_name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formInput1">
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									name="email"
									value={formData.email}
									onChange={handleChange}
									placeholder="Email daxil edin"
									isInvalid={!!errors.email}
									className={errors.email ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.email}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formInput1">
								<Form.Label>Şifrə</Form.Label>
								<Form.Control
									type="text"
									name="password"
									value={formData.password}
									onChange={handleChange}
									placeholder="Şifrəni daxil edin"
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId="formInput4">
								<Form.Label>Telefon</Form.Label>
								<Form.Control
									type="text"
									name="phone"
									value={formData.phone}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="+994_________"
									isInvalid={!!errors.phone}
									className={errors.phone ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.phone}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formDate" className="select-form">
								<Form.Label>Doğum tarixi</Form.Label>
								<Form.Control
									type="date"
									name="birth_day"
									value={formData.birth_day}
									onChange={handleChange}
									isInvalid={!!errors.birth_day}
									className={`pr-5 ${errors.birth_day ? "input-error" : ""}`}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.birth_day}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col className="group-checkbox">
							<Form.Group controlId="formGroup">
								<Form.Label>Qrup seçin</Form.Label>
								<div className="position-relative">
									<Dropdown>
										<Dropdown.Toggle
											id="dropdown-basic"
										>
											{getDropdownLabel()}
											<svg
												width="14"
												height="8"
												viewBox="0 0 14 8"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1 1L7 7L13 1"
													stroke="#6F767E"
													strokeWidth="2"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</Dropdown.Toggle>
										<Dropdown.Menu className="add-dropdown-menu">
											{Array.isArray(groups) && groups.length > 0 ? (
												Array.from(new Set(groups.map((group) => getGroupPrefix(group.name)))).map(
													(prefix) => (
														<Form.Check
															key={prefix}
															type="checkbox"
															id={`group-checkbox-${prefix}`}
															label={prefix}
															name="groupPrefix"
															value={prefix}
															checked={formData.groups.some(
																(item) => getGroupPrefix(groups.find((g) => g.id.toString() === item.group_id)?.name) === prefix
															)}
															onChange={(e) => {
																const { checked } = e.target;
																const selectedGroups = groups
																	.filter((group) => getGroupPrefix(group.name) === prefix)
																	.map((group) => ({ group_id: group.id.toString() }));

																setFormData((prevData) => {
																	const newGroups = checked
																		? [...prevData.groups, ...selectedGroups] 
																		: prevData.groups.filter(
																			(item) => getGroupPrefix(groups.find((g) => g.id.toString() === item.group_id)?.name) !== prefix
																		);
																	return {
																		...prevData,
																		groups: newGroups,
																	};
																});
															}}
														/>
													)
												)
											) : (
												<Dropdown.Item disabled>Qruplar mövcud deyil</Dropdown.Item>
											)}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</Form.Group>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button className="modal-btn btn-green" onClick={handleSubmit}>
					Düzəlişləri yadda saxla
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default EditStudent;
