import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function MyGroups({ getData }) {
  const navigate = useNavigate();

  const handleGroupClick = (groupId) => {
    // Navigate to GradeTable with group_id in the URL
    navigate("/grade", { state: { group_id: groupId } });
  };

  if (!getData || !Array.isArray(getData.groups) || getData.groups.length === 0) {
    return <p>Hazırda qrupunuz yoxdur.</p>;
  }

  return (
    <>
      {getData.groups.map((group) => (
        <Col key={group.id} md={6} className="mb-4">
          <div 
            className="user__groups-item" 
            onClick={() => handleGroupClick(group.id)} 
            style={{ cursor: 'pointer' }} // Make it look clickable
          >
            <p className="user__groups-date">Başlanma tarixi: {group.start_date}</p>
            <h4 className="user__groups-name">{group.name}</h4>
          </div>
        </Col>
      ))}
    </>
  );
}

export default MyGroups;
