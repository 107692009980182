import { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import GroupsTableItem from "./GroupsTableItem";
import { groupsTableHeading, groupsTableSelect } from "../../utils/data";
import AddGroup from "./AddGroup";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchGroups } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { useDispatch } from 'react-redux';
import { setGroupName } from '../../store/action';

const GroupsTable = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openSelectTwo, setOpenSelectTwo] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [modalShow, setModalShow] = useState(false);
	const [getData, setGetData] = useState([]);
	const [loading, setLoading] = useState(true);
	const currentPage = parseInt(searchParams.get('page')) || 1;
	const pagination = parseInt(searchParams.get('pagination')) || 10;
	const [totalPages, setTotalPages] = useState(0);
	const [sortField, setSortField] = useState('name');
	const [sortOrder, setSortOrder] = useState('asc');
	const [search, setSearch] = useState('');


	const handleModalClose = () => setModalShow(false);
	const handleModalShow = () => setModalShow(true);
	const groupNameFromRedux = useSelector((state) => state.groupName);


	useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

	useEffect(() => {
		if (groupNameFromRedux && groupNameFromRedux !== search) {
			setSearch(groupNameFromRedux);
			setSearchParams({ page: 1, pagination });
		}
	}, [pagination, setSearchParams]);


	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const data = await fetchGroups(navigate, pagination, currentPage, search, sortField, sortOrder);
				setGetData(data.items);
				setTotalPages(data.totalPages);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [currentPage, pagination, search, sortField, sortOrder]);

	const handlePageChange = (event, value) => {
		setSearchParams({ page: value, pagination });
	};

	const handlepaginationChange = (event) => {
		setSearchParams({ page: 1, pagination: Number(event.target.value) });
	};

	const handleSortChange = (value) => {
		const [field, order] = value.split(' ');
		setSortField(field);
		setSortOrder(order);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setSearchParams({ page: 1, pagination });
	};

	return (
		<>
			<AddGroup show={modalShow} handleClose={handleModalClose} />
			<div className="groups">
				<div className="groups-header">
					<button className="add-button" onClick={handleModalShow}>
						<svg
							width="23"
							height="22"
							viewBox="0 0 23 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="8.5"
								y="8"
								width="13"
								height="13"
								stroke="#141B34"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M16.5 8V1H1.5V16H8.5"
								stroke="#141B34"
								strokeWidth="1.5"
								strokeLinejoin="round"
							/>
							<path
								d="M18 14.5H12M15 11.5V17.5"
								stroke="#141B34"
								strokeWidth="1.5"
							/>
						</svg>

						<div>Yeni qrup əlavə et</div>
					</button>

					<div className="header-right">
						<FilterSelect data={groupsTableSelect} onSelect={handleSortChange} />

						<div className="search-box">
							<input
								type="text"
								placeholder="Axtar"
								value={search}
								onChange={handleSearchChange}
							/>
						</div>
					</div>
				</div>
				<div className="groups-body">
					<table>
						<thead>
							<tr>
								{groupsTableHeading.map((item, i) => (
									<th key={item.value}>
										<div className={`${i === 0 ? "left" : ""} column-content`}>
											{item.name}
											{sortField === item.value && (
												<span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
											)}
										</div>
									</th>
								))}
								<th>
									<div className="right column-content">Əməliyyatlar</div>
								</th>
							</tr>
						</thead>

						<tbody>
							{getData &&
								getData.map((group, i) => (
									<GroupsTableItem data={group} key={i} loading={loading} setLoading={setLoading} />
								))}
						</tbody>
					</table>
				</div>
				<div className="groups-footer">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						className="pagination"
						variant="outlined"
						shape="rounded"
					/>

					<Form.Select
						className={`selection ${openSelectTwo ? "active" : ""}`}
						value={pagination}
						onChange={handlepaginationChange}
					>
						<option value="10">10</option>
						<option value="25">25</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</Form.Select>
				</div>
			</div>
		</>
	);
};

export default GroupsTable;
