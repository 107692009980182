import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PasswordIcon from "../components/PasswordIcon";
import * as consts from "../consts/Consts";
import { handleUnauthorizedError } from "../functions/authUtils";
import { useNavigate } from "react-router-dom";
import { fetchAboutUs } from "../functions/apiService";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({});

  const handleResetPassword = (e) => {
    e.preventDefault();

    let errors = {};

    let minLengthRegex = /^.{8,}$/;
    let leastUppercase = /(?=.*[A-Z])/;
    let leastLowercase = /(?=.*[a-z])/;
    let leastDigit = /(?=.*\d)/;

    if (newPassword.length === 0) {
      errors.newPassword = "Şifrə boş olmamalıdır.";
    } else {
      let messages = [];
      if (!minLengthRegex.test(newPassword)) {
        messages.push("minimum 8 simvoldan");
      }
      if (!leastUppercase.test(newPassword)) {
        messages.push("1 böyük hərfdən");
      }
      if (!leastLowercase.test(newPassword)) {
        messages.push("1 kiçik hərfdən");
      }
      if (!leastDigit.test(newPassword)) {
        messages.push("1 rəqəmdən");
      }
      if (messages.length > 0) {
        errors.newPassword = `Şifrə ${messages.join(", ")} ibarət olmalıdır.`;
      } else {
        errors.newPassword = "";
      }
    }

    if (confirmPassword.length === 0) {
      errors.confirmPassword = "Şifrə yenidən daxil olunmalıdır.";
    } else if (confirmPassword !== newPassword) {
      errors.confirmPassword = "Şifrə düzgün deyil.";
    } else if (confirmPassword === newPassword) {
      errors.confirmPassword = "";
    }

    setError(errors);
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
  useState(false);
  
  const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchAboutUs(navigate);
          consts.setAboutInfo({
            logo_black: data.logo_black,
          });
        } catch (error) {
          handleUnauthorizedError(error, navigate);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [navigate]);
    
  return (
    <>
      <section className="login">
        <Container fluid>
          <Row>
            <Col md={6} className="login__left">
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <div className="login__logo mb-5">
                  <img
                    // src={consts.LOGO_PATH_LOGIN}
                    src={consts.getAboutInfo()?.logo_black}
                    alt=""
                    className={`${consts.getAboutInfo()?.company_name === "Performance" ? "" : "login-logo"
                      }`}
                  />                </div>
                <Form onSubmit={handleResetPassword} style={{ width: "300px" }}>
                  <p className="text-center login-left__title mb-5">
                    Xoş gördük! Məlumatlarınızı yazaraq daxil olun.
                  </p>

                  <Form.Group controlId="formLogin" className="mb-3" id="form-input">
                    <Form.Label>Yeni şifrə</Form.Label>
                    <div className="input-password">
                      <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={`${error.newPassword ? "input-error" : ""}`}
                      />
                      <PasswordIcon
                        showPassword={showNewPassword}
                        togglePasswordVisibility={() =>
                          setShowNewPassword((prev) => !prev)
                        }
                      />
                    </div>

                    {error.newPassword && (
                      <div className="text-danger">{error.newPassword}</div>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formLogin" id="form-input">
                    <Form.Label>Yeni şifrənin təkrarı</Form.Label>
                    <div className="input-password">

                      <Form.Control
                        type={showNewPasswordConfirmation ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={`${error.confirmPassword ? "input-error" : ""}`}
                      />
                      <PasswordIcon
                        showPassword={showNewPasswordConfirmation}
                        togglePasswordVisibility={() =>
                          setShowNewPasswordConfirmation((prev) => !prev)
                        }
                      />
                    </div>
                    {error.confirmPassword && (
                      <div className="text-danger">{error.confirmPassword}</div>
                    )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100 login__btn"
                  >
                    Şifrəni sıfırla
                  </Button>
                </Form>
              </div>
            </Col>
            <Col md={6} className="login__right">
              <div className="login__background">
                <div className="h-100">
                  <div className="login__top">
                    <div className="img-container_login">
                      <div className="login__img login__img-back">
                        <img src="/img/iphone_2.png" alt="" />
                      </div>
                      <div className="login__img login__img-front">
                        <img src="/img/iphone_1.png" alt="" />
                      </div>
                    </div>

                    <div className="login__qr">
                      <div className="login-qr__img">
                        <img src="/img/qr.png" alt="" />
                      </div>
                      <p className="login-info">
                        QR kodu oxudaraq tətbiqi yükləyin
                      </p>
                    </div>
                  </div>
                  <div className="login__bottom">
                    <h1>Performance App</h1>
                    <p className="login__text">
                      iOS və ya Play Market vasistəsilə Coders Performans mobil
                      tətbiqini yükləyib təhsilinizlə bağlı bütün prosesləri
                      istədiyiniz yerdən izləyə bilərsiniz.
                    </p>
                    <span className="icon-btns"></span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ResetPassword;
