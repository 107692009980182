import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as consts from "../consts/Consts";
import {
	generateRandomNonce,
	generateSignature,
	getCurrentDatetime,
	getCurrentEpochDivided,
} from "../functions/authUtils";
import PasswordIcon from "../components/PasswordIcon";
import { fetchAboutUs } from "../functions/apiService";
import { handleUnauthorizedError } from "../functions/authUtils";

function Login({ setRole }) {
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const [loginError, setLoginError] = useState(false);

	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutUs(navigate);
				consts.setAboutInfo({
					logo_black: data.logo_black,
				});
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [navigate]);

	const apiUrlLogin = `${consts.API_URL}/auth/login`;

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		if (!login || !password) {
			setError("Məlumatları daxil edin.");
			return;
		}

		setError("");

		const nonceLength = 8;
		const nonce = generateRandomNonce(nonceLength);
		const datetime = getCurrentDatetime();
		const salt = "NSP-Coders_(!#12!)_";
		const epochDivided = getCurrentEpochDivided();
		const signature = generateSignature(datetime, nonce, salt, epochDivided);

		const data = {
			email: login,
			password: password,
		};

		try {
			const response = await fetch(apiUrlLogin, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Datetime: datetime,
					Nonce: nonce,
					Signature: signature,
				},
				body: JSON.stringify(data),
			});

			if (response.ok) {
				const responseData = await response.json();
				const { access_token, role } = responseData.data;

				localStorage.setItem("token", access_token);
				setRole(role);

				setIsLoggedIn(true);

				if (role === "admin") {
					navigate("/admin");
				} else if (role === "teacher") {
					navigate("/teacher");
				}
				else if (role === "student") {
				  navigate("/student");
				} 
				else {
					navigate("/dashboard");
				}
			} else {
				setLoginError(true);
				setError("Login və ya şifrə düz deyil.");
			}
		} catch (error) {
			console.error("Giriş zamanı xəta", error);
			setLoginError(true);
			setError("Xəta baş verdi. Yenidən cəhd edin.");
		}
	};
	const togglePasswordVisibility = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	return (
		<section className="login">
			<Container fluid>
				<Row>
					<Col md={6} className="login__left">
						<div className="d-flex flex-column align-items-center justify-content-center h-100">
							<div className="login__logo mb-5">
								<img
									// src={consts.LOGO_PATH_LOGIN}
									src={consts.getAboutInfo()?.logo_black}
									alt=""
									className={`${consts.getAboutInfo()?.company_name === "Performance" ? "" : "login-logo"
										}`}
								/>
							</div>
							<Form style={{ width: "300px" }} onSubmit={handleFormSubmit}>
								<p className="text-center login-left__title mb-5">
									Xoş gördük! Məlumatlarınızı yazaraq daxil olun.
								</p>

								{error && <Alert variant="danger">{error}</Alert>}

								<Form.Group controlId="formLogin">
									<Form.Label>E-mail</Form.Label>
									<Form.Control
										type="text"
										placeholder="performans@gmail.com"
										value={login}
										onChange={(e) => setLogin(e.target.value)}
									/>
								</Form.Group>

								<Form.Group
									controlId="formPassword"
									className="mt-3 input-password"
								>
									<Form.Label>Parol</Form.Label>
									<div className="password-wrapper input-password">
										<Form.Control
											type={showPassword ? "text" : "password"}
											placeholder="*******"
											id="login_password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<PasswordIcon
											showPassword={showPassword}
											togglePasswordVisibility={togglePasswordVisibility}
										/>
									</div>
								</Form.Group>
								<div className="d-flex justify-content-between mt-3">
									<Form.Group controlId="formRememberMe">
										<Form.Check
											type="checkbox"
											label="Yadda saxla"
											checked={rememberMe}
											onChange={(e) => setRememberMe(e.target.checked)}
										/>
									</Form.Group>
									<Link
										to="forget-password"
										className="text-end password-forget"
										onClick={() => setShowForgetPassword(true)}
									>
										Parolu unutmusunuz?
									</Link>
								</div>

								<Button
									variant="primary"
									type="submit"
									className="mt-4 w-100 login__btn"
								>
									Daxil ol
								</Button>

								{isLoggedIn && (
									<Alert variant="success" className="mt-3">
										Uğurlu giriş
									</Alert>
								)}
							</Form>
						</div>
					</Col>
					<Col md={6} className="login__right">
						<div className="login__background">
							<div className="h-100">
								<div className="login__top">
									<div className="img-container_login">
										<div className="login__img login__img-back">
											<img src="/img/iphone_2.png" alt="" />
										</div>
										<div className="login__img login__img-front">
											<img src="/img/iphone_1.png" alt="" />
										</div>
									</div>

									<div className="login__qr">
										<div className="login-qr__img">
											<img src="/img/qr.png" alt="" />
										</div>
										<p className="login-info">
											QR kodu oxudaraq tətbiqi yükləyin
										</p>
									</div>
								</div>
								<div className="login__bottom">
									<h1>Performance App</h1>
									<p className="login__text">
										iOS və ya Play Market vasistəsilə Coders Performans mobil
										tətbiqini yükləyib təhsilinizlə bağlı bütün prosesləri
										istədiyiniz yerdən izləyə bilərsiniz.
									</p>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default Login;
