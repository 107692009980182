import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import UserProfile from "../UserProfile";
import { fetchAboutMe } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";
import { createHeaders } from "../../functions/apiService";
import { clearToken } from "../../functions/tokenService";
import * as consts from "../../consts/Consts";

function ProfileMenuDropdown({ show, onToggle, setRole, role, setShowModal }) {
	const navigate = useNavigate();

	const [getData, setGetData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchAboutMe(navigate);
				setGetData(data);
			} catch (error) {
				handleUnauthorizedError(error, navigate);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const handleLogout = async () => {
		const headers = createHeaders();
		try {
			const response = fetch(`${consts.API_URL}/auth/logout`, {
				method: "POST",
				headers: headers,
			});

			// const data = await response.json();
			// if (response.ok) {
			//   setRole(null);
			//   clearToken()
			//   navigate("/", { replace: true });
			//   window.location.reload();
			// } 
			setRole(null);
			clearToken()
			navigate("/", { replace: true });
			window.location.reload();

		} catch (error) {
			handleUnauthorizedError(error, navigate);
		}
	};
	//   const handleLogout = () => {
	//     localStorage.removeItem("token");
	//     localStorage.removeItem("signature");
	//     localStorage.removeItem("role");
	//     setRole(null);
	//     navigate("/", { replace: true });

	//     window.history.pushState(null, null, window.location.href);
	//     window.addEventListener("popstate", () => {
	//       navigate("/", { replace: true });
	//     });
	//   };

	return (
		<Dropdown
			show={show}
			onToggle={onToggle}
			id="dropdown-basic"
			className="profile-menu-dropdown"
		>
			<Dropdown.Toggle as="div">
				<div className="header-img header-profile" onClick={onToggle}>
					{getData.image ? (
						<img src={getData.image} alt="" className="profile-avatar-image" />
					) : (
						<UserProfile imageSize={35} containerSize={43} />
					)}
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item eventKey="1">
					<svg
						width="22"
						height="22"
						viewBox="0 0 22 22"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10 19.01H1C1 15.144 4.58172 12.01 9 12.01C10.8919 12.01 12.6304 12.5846 14 13.5453M13 5.01001C13 7.21915 11.2091 9.01001 9 9.01001C6.79086 9.01001 5 7.21915 5 5.01001C5 2.80087 6.79086 1.01001 9 1.01001C11.2091 1.01001 13 2.80087 13 5.01001Z"
							stroke="#141B34"
							strokeWidth="1.5"
						/>
						<path
							d="M13 21.01H12.25V21.76H13V21.01ZM13 18.51L12.4697 17.9797L12.25 18.1993V18.51H13ZM15.5 21.01V21.76H15.8107L16.0303 21.5403L15.5 21.01ZM18.5 13.01L19.0303 12.4797C18.8897 12.339 18.6989 12.26 18.5 12.26C18.3011 12.26 18.1103 12.339 17.9697 12.4797L18.5 13.01ZM21 15.51L21.5303 16.0403C21.8232 15.7474 21.8232 15.2726 21.5303 14.9797L21 15.51ZM13.75 21.01V18.51H12.25V21.01H13.75ZM13 21.76H15.5V20.26H13V21.76ZM13.5303 19.0403L19.0303 13.5403L17.9697 12.4797L12.4697 17.9797L13.5303 19.0403ZM17.9697 13.5403L20.4697 16.0403L21.5303 14.9797L19.0303 12.4797L17.9697 13.5403ZM20.4697 14.9797L14.9697 20.4797L16.0303 21.5403L21.5303 16.0403L20.4697 14.9797Z"
							fill="#141B34"
						/>
					</svg>
					<NavLink to="/edit-profile" className="profile-menu-nav">
						Profili redaktə et
					</NavLink>
				</Dropdown.Item>
				{role !== "admin" && (
					<Dropdown.Item eventKey="2" onClick={() => setShowModal(true)}>
						<svg
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M7 19.2194L7.28756 18.5267L6.9888 18.4026L6.69356 18.5348L7 19.2194ZM3 21.01L2.27577 20.8151C2.20053 21.0947 2.29294 21.3926 2.51319 21.5805C2.73343 21.7685 3.04218 21.8128 3.30644 21.6945L3 21.01ZM4 17.294L4.72423 17.4889L4.84157 17.0529L4.51116 16.7452L4 17.294ZM11 0.26001C5.10029 0.26001 0.25 4.8137 0.25 10.51H1.75C1.75 5.71372 5.85527 1.76001 11 1.76001V0.26001ZM20.25 10.51C20.25 15.3063 16.1447 19.26 11 19.26V20.76C16.8997 20.76 21.75 16.2063 21.75 10.51H20.25ZM11 1.76001C16.1447 1.76001 20.25 5.71372 20.25 10.51H21.75C21.75 4.8137 16.8997 0.26001 11 0.26001V1.76001ZM6.71244 19.912C8.02757 20.458 9.4781 20.76 11 20.76V19.26C9.6776 19.26 8.42237 18.9978 7.28756 18.5267L6.71244 19.912ZM6.69356 18.5348L2.69356 20.3255L3.30644 21.6945L7.30644 19.9039L6.69356 18.5348ZM0.25 10.51C0.25 13.3901 1.49634 15.9871 3.48884 17.8428L4.51116 16.7452C2.80106 15.1525 1.75 12.9443 1.75 10.51H0.25ZM3.72423 21.2049L4.72423 17.4889L3.27577 17.0991L2.27577 20.8151L3.72423 21.2049Z"
								fill="#141B34"
							/>
							<path
								d="M9.5 9.04847C9.5 8.1988 10.1716 7.51001 11 7.51001C11.8284 7.51001 12.5 8.1988 12.5 9.04847C12.5 9.35474 12.4127 9.64011 12.2623 9.87985C11.8141 10.5944 11 11.2757 11 12.1254V12.51"
								stroke="#141B34"
								strokeWidth="1.5"
							/>
							<path
								d="M11 14.51H11.009"
								stroke="#141B34"
								strokeWidth="1.5"
								strokeLinecap="square"
								strokeLinejoin="round"
							/>
						</svg>
						<div className="profile-menu-nav">Dəstək xidməti</div>
					</Dropdown.Item>
				)}
				<Dropdown.Item eventKey="3" onClick={handleLogout}>
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 1.10503C11.543 1.04242 11.0755 1.01001 10.6 1.01001C5.29807 1.01001 1 5.03945 1 10.01C1 14.9806 5.29807 19.01 10.6 19.01C11.0755 19.01 11.543 18.9776 12 18.915"
							stroke="#141B34"
							strokeWidth="1.5"
						/>
						<path
							d="M19 10.01H9M19 10.01L15.9998 7.01001M19 10.01L15.9998 13.01"
							stroke="#141B34"
							strokeWidth="1.5"
						/>
					</svg>
					<p className="profile-menu-nav" >
						Çıxış
					</p>
					{/* <NavLink to="/" className="profile-menu-nav" onClick={handleLogout}>
            Çıxış
          </NavLink> */}
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default ProfileMenuDropdown;
