import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import {
  draftersTable,
  draftersTableHeading,
  draftersTableSelect,
} from "../../utils/data";
import DraftersTableItem from "./DraftersTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { fetchPendings } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function DraftersTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const pagination = parseInt(searchParams.get('pagination')) || 10;
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState('full_name');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPendings(
          navigate,
          pagination,
          currentPage,
          search,
          sortField,
          sortOrder
        );
        setGetData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination, currentPage, navigate, search, sortField, sortOrder]);

  useEffect(() => {
    if (!searchParams.get('page') || !searchParams.get('pagination')) {
      setSearchParams({
        page: currentPage,
        pagination: pagination,
      });
    }
  }, [searchParams, setSearchParams, currentPage, pagination]);

  const handlePageChange = (event, value) => {
    setSearchParams({ page: value, pagination });
  };

  const handlepaginationChange = (event) => {
    setSearchParams({ page: 1, pagination: Number(event.target.value) });
  };

  const handleSortChange = (value) => {
    const [field, order] = value.split(' ');
    setSortField(field);
    setSortOrder(order);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setSearchParams({ page: 1, pagination });
  };

  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <FilterSelect
              data={draftersTableSelect}
              onSelect={handleSortChange}
            />

            <div className="search-box">
              <input
                type="text"
                placeholder="Axtar"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {draftersTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content `}>
                      {item.name}
                      {sortField === item.value && (
                        <span>{sortOrder === "asc" ? "↓" : "↑"}</span>
                      )}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {getData &&
                getData.map((drafters, i) => (
                  <DraftersTableItem data={drafters} key={i} />
                ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />
          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={pagination}
            onChange={handlepaginationChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>


      </div>
    </>
  );
}

export default DraftersTable;
