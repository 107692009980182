import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import {
  teachersActivityTable,
  teachersActivityTableHeading,
  teachersActivityTableSelect,
} from "../../utils/data";
import TeachersActivityTableItem from "./TeachersActivityTableItem";
import Form from "react-bootstrap/Form";
import FilterSelect from "./FilterSelect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchTeachersActivity } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function TeachersActivityTable() {
  const [openSelectTwo, setOpenSelectTwo] = useState(false);
  const navigate = useNavigate();
  const [modalShowAdd, setModalShowAdd] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPage = parseInt(searchParams.get('page')) || 1;
	const pagination = parseInt(searchParams.get('pagination')) || 10;
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('created_at');  
  const [sortOrder, setSortOrder] = useState('desc'); 


  useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTeachersActivity(navigate, pagination, currentPage, search, sortField, sortOrder);
        setGetData(data.items || []);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination, currentPage, search, navigate, sortField, sortOrder]);

  const handlePageChange = (event, value) => {
		setSearchParams({ page: value, pagination });
	};

	const handlepaginationChange = (event) => {
		setSearchParams({ page: 1, pagination: Number(event.target.value) }); 
	};

	const handleSortChange = (value) => {
		const [field, order] = value.split(' ');
		setSortField(field);
		setSortOrder(order);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setSearchParams({ page: 1, pagination }); 
	};
  return (
    <>
      <div className="groups no-add-layer">
        <div className="groups-header">
          <div className="header-right">
            <FilterSelect data={teachersActivityTableSelect} onSelect={handleSortChange}/>

            <div className="search-box">
              <input type="text" placeholder="Axtar" onChange={handleSearchChange} />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {teachersActivityTableHeading.map((item, i) => (
                 <th key={item.value}>
                 <div
                   className={`${i === 0 ? "left" : ""} column-content ${i === teachersActivityTableHeading.length - 1 ? "right" : ""}`}
                 >
                   {item.name}
                   {sortField === item.value && (
                     <span>{sortOrder === 'asc' ? '↓' : '↑'}</span>
                   )}
                 </div>
               </th>
               
                ))}
              </tr>
            </thead>

            <tbody>
              {getData && getData?.map((item, i) => (
                <TeachersActivityTableItem data={item} key={i} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />
          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={pagination}
            onChange={handlepaginationChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default TeachersActivityTable;
