import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination } from "@mui/material";
import { templates, templatesTableSelecting, templatesTableHeading } from "../../utils/data";
import TemplatesTableItem from "./TemplatesTableItem";
import FilterSelect from "./FilterSelect";
import { Form } from "react-bootstrap";
import AddTemplate from "./AddTemplate";
import { fetchTemplates } from "../../functions/apiService";
import { handleUnauthorizedError } from "../../functions/authUtils";

function TemplatesTable() {
  const navigate = useNavigate();
  const [openSelectTwo, setOpenSelectTwo] = useState(false);

  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => {
    setAddShow(false);
  };

 
	const [searchParams, setSearchParams] = useSearchParams();
	const currentPage = parseInt(searchParams.get('page')) || 1;
	const pagination = parseInt(searchParams.get('pagination')) || 10;
  const [totalPages, setTotalPages] = useState(0);
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('note');  
  const [sortOrder, setSortOrder] = useState('asc'); 


  useEffect(() => {
		if (!searchParams.get('page') || !searchParams.get('pagination')) {
			setSearchParams({
				page: currentPage,
				pagination: pagination,
			});
		}
	}, [searchParams, setSearchParams, currentPage, pagination]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTemplates(navigate, pagination, currentPage, search, sortField, sortOrder);
        setGetData(data.items);
        setTotalPages(data.totalPages);
      } catch (error) {
        handleUnauthorizedError(error, navigate);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pagination, currentPage, search, sortField, sortOrder, navigate]);

	const handlePageChange = (event, value) => {
		setSearchParams({ page: value, pagination });
	};

	const handlepaginationChange = (event) => {
		setSearchParams({ page: 1, pagination: Number(event.target.value) }); 
	};

	const handleSortChange = (value) => {
		const [field, order] = value.split(' ');
		setSortField(field);
		setSortOrder(order);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setSearchParams({ page: 1, pagination }); 
	};
  

  return (
    <>
      <AddTemplate show={addShow} handleCloseModal={handleAddClose} />
      <div className="groups">
        <div className="groups-header">
          <button className="add-button" onClick={() => setAddShow(true)}>
            <svg
              width="23"
              height="22"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="8.5"
                y="8"
                width="13"
                height="13"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.5 8V1H1.5V16H8.5"
                stroke="#141B34"
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
              <path
                d="M18 14.5H12M15 11.5V17.5"
                stroke="#141B34"
                strokeWidth="1.5"
              />
            </svg>

            <div>Yeni şablon əlavə et</div>
          </button>

          <div className="header-right">
            <FilterSelect
              data={templatesTableSelecting}
              onSelect={handleSortChange}
            />

            <div className="search-box">
              <input type="text" placeholder="Axtar" onChange={handleSearchChange} value={search} />
            </div>
          </div>
        </div>
        <div className="groups-body">
          <table>
            <thead>
              <tr>
                {templatesTableHeading.map((item, i) => (
                  <th key={item.value}>
                    <div className={`${i === 0 ? "left" : ""} column-content`}>
                      {item.name}
                    </div>
                  </th>
                ))}
                <th>
                  <div className="right column-content">Əməliyyatlar</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {getData &&
                getData?.map((item, i) => (
                  <TemplatesTableItem
                    data={item}
                    key={i}
                  />
                ))}
            </tbody>
          </table>
        </div>
        <div className="groups-footer">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            className="pagination"
            variant="outlined"
            shape="rounded"
          />

          <Form.Select
            className={`selection ${openSelectTwo ? "active" : ""}`}
            value={pagination}
            onChange={handlepaginationChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default TemplatesTable;
