



import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom'
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import InputMask from "react-input-mask";
import * as consts from "../../consts/Consts";
import {
	fetchSubjects,
	fetchTeachers,
	createHeaders,
} from "../../functions/apiService";
import CustomSelect from '../CustomSelect'
import CustomDropdown from '../CustomDropdown'


const weekDaysMapping = {
	"B.e.": "monday",
	"Ç.a.": "tuesday",
	"Ç.": "wednesday",
	"C.a.": "thursday",
	"C.": "friday",
	"Ş.": "saturday",
	"B.": "sunday",
};

const AddGroup = ({ show, handleClose, id, data }) => {
	const navigate = useNavigate();
	const [perPage, setPerPage] = useState(5);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loadingMore, setLoadingMore] = useState(false);

	const [formData, setFormData] = useState({
		subject_id: "",
		teacher_id: "",
		name: "",
		type: "",
		start_date: "",
		schedules: [
			{
				week_day: "",
				time: "",
				end_time: ""
			},
		],
		week_day: [],
		time: {},
		end_time: {}
	});

	const [errors, setErrors] = useState({});

	const resetFormData = () => {
		setFormData({
			subject_id: "",
			teacher_id: "",
			name: "",
			type: "",
			start_date: "",
			schedules: [
				{
					week_day: "",
					time: "",
					end_time: ""
				},
			],
			week_day: [],
			time: {},
			end_time: {}
		});
		setErrors({});
	};

	const handleChange = (e) => {
		const { name, value } = e.target || { name: e.name, value: e.value };
	
		if (name === "name" && value.includes('-')) {
			return; 
		}
	
		setFormData((prevData) => {
			let updatedName = prevData.name;
	
			if (name === "subject_id") {
				const selectedSubject = getData.find(subject => subject.id === value);
				const subjectName = selectedSubject?.translations[0]?.name || "";
	
				const nameParts = prevData.name.split(' - ');
				const manualName = nameParts[0] || "";
	
				updatedName = `${manualName} - ${subjectName}`.trim();
			}
	
			if (name === "name") {
				updatedName = value; 
			}
	
			return {
				...prevData,
				[name]: value,
				name: updatedName,
			};
		});
	
		if (value && errors[name]) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: undefined,
			}));
		}
	};
	
	

	const validateTime = (value) => {
		const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
		if (!regex.test(value)) return false;

		const [hours, minutes] = value.split(":").map(Number);
		const time = hours * 60 + minutes;

		const minTime = 9 * 60;
		const maxTime = 22 * 60;

		return time >= minTime && time <= maxTime;
	};

	const validateForm = () => {
		const newErrors = {};

		if (!formData.name.trim()) {
			newErrors.name = "Qrupun adını daxil edin";
		} else if (formData.name.length > 64) {
			newErrors.name = "Qrupun adı 64 simvoldan çox olmamalıdır.";
		}

		if (!formData.type) {
			newErrors.type = "Tədrisin təşkilini seçin.";
		}

		if (!formData.subject_id) {
			newErrors.subject_id = "Tədrisin istiqamətini seçin";
		}

		if (!formData.start_date) {
			newErrors.start_date = "Başlanma tarixini seçin.";
		}

		if (!formData.teacher_id) {
			newErrors.teacher_id = "Müəllimi seçin.";
		}

		if (formData.week_day.length === 0) {
			newErrors.week_day = "Ən azı bir gün seçilməlidir.";
		}

		formData.week_day.forEach((day) => {
			const time = formData.time[day];
			const endTime = formData.end_time[day];

			const validateDayTime = (day, time, endTime) => {
				if (!time && !endTime) {
					return `${day} günü üçün başlama və bitmə vaxtını daxil edin.`;
				}

				if (!time) {
					return `${day} günü üçün başlama vaxt daxil edin.`;
				} else if (!validateTime(time)) {
					return `${day} günü üçün başlama vaxtı düzgün formatda deyil (09:00 - 22:00).`;
				}

				if (!endTime) {
					return `${day} günü üçün bitmə vaxtını daxil edin.`;
				} else if (!validateTime(endTime)) {
					return `${day} günü üçün bitmə vaxtı düzgün formatda deyil (09:00 - 22:00).`;
				} else if (time >= endTime) {
					return `${day} günü üçün bitmə vaxtı başlanğıc vaxtından sonra olmalıdır.`;
				}

				return null;
			};

			const error = validateDayTime(day, time, endTime);
			if (error) {
				newErrors[day] = error;
			}
		});

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleCloseModal = () => {
		resetFormData();
		handleClose();
	};

	// const validateTime = (value) => {
	// 	const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
	// 	return regex.test(value);
	// };

	const handleCheckboxChange = (e) => {
		const { value, checked } = e.target;
		const weekDayEnglish = weekDaysMapping[value];

		setFormData((prevData) => {
			const newWeekDays = checked
				? [...prevData.week_day, value]
				: prevData.week_day.filter((day) => day !== value);

			const newSchedules = newWeekDays.map((day) => ({
				week_day: weekDaysMapping[day],
				time: prevData.time[day] || "",
				end_time: prevData.end_time[day] || "",
			}));

			return {
				...prevData,
				week_day: newWeekDays,
				schedules: newSchedules,
			};
		});
	};

	const handleTimeInputChange = (e, label, isEndTime = false) => {
		const { value } = e.target;
		const weekDayEnglish = weekDaysMapping[label];

		setFormData((prevData) => {
			const newSchedules = prevData.schedules.map((schedule) =>
				schedule.week_day === weekDayEnglish
					? { ...schedule, time: isEndTime ? schedule.time : value, end_time: isEndTime ? value : schedule.end_time }
					: schedule
			);

			return {
				...prevData,
				[isEndTime ? 'end_time' : 'time']: {
					...prevData[isEndTime ? 'end_time' : 'time'],
					[label]: value,
				},
				schedules: newSchedules,
			};
		});
	};

	const [getData, setGetData] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [loading, setLoading] = useState(true);

	const [loadingTeachers, setLoadingTeachers] = useState(false);
	const [currentTeachersPage, setCurrentTeachersPage] = useState(1);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchSubjects();
				setGetData(data);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchTeachers(null, 1000);
				setTeachers(data.items);
			} catch (error) {
				// console.error("Ошибка при получении данных:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);


	const handleSubmit = async () => {
		if (validateForm()) {
			const schedules = formData.week_day.map((weekDay) => ({
				week_day: weekDaysMapping[weekDay],
				time: formData.time[weekDay] || "",
				end_time: formData.end_time[weekDay] || "",

			}));

			const headers = createHeaders();

			try {
				const response = await fetch(`${consts.API_URL}/admin/group`, {
					method: "POST",
					headers: headers,
					body: JSON.stringify({
						subject_id: formData.subject_id,
						teacher_id: formData.teacher_id,
						name: formData.name,
						type: formData.type,
						start_date: formData.start_date,
						schedules: schedules,
					}),
				});

				const data = await response.json();
				if (response.ok) {
					console.log("Group added successfully:", data);
					resetFormData();
					handleClose();
					window.location.reload();
				} else {
					if (
						data.errors &&
						data.errors.name &&
						data.errors.name.includes("The name has already been taken.")
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							name: "Bu qrup adı artıq istifadə olunur.",
						}));
					} else {
						// console.error("Error adding group:", data.message);
					}
				}
			} catch (error) {
				// console.error("Request failed:", error);
			}
		}
	};

	const handleLessonSubmit = async () => {
		if (validateForm()) {
			const schedules = formData.week_day.map((weekDay) => ({
				week_day: weekDaysMapping[weekDay],
				time: formData.time[weekDay] || "",
				end_time: formData.end_time[weekDay] || "",
			}));

			const headers = createHeaders();

			try {
				const response = await fetch(`${consts.API_URL}/admin/group`, {
					method: "POST",
					headers: headers,
					body: JSON.stringify({
						subject_id: formData.subject_id,
						teacher_id: formData.teacher_id,
						name: formData.name,
						type: formData.type,
						start_date: formData.start_date,
						schedules: schedules,
					}),
				});

				const data = await response.json();
				if (response.ok) {
					console.log("Group added successfully:", data);
					// Reset the relevant fields and remove everything after the '-' in the name
					setFormData((prevData) => {
						const trimmedName = prevData.name.split(' - ')[0].trim(); // Keep only the part before the '-'
						return {
							subject_id: "", // Clear subject_id immediately
							teacher_id: "", // Clear teacher_id
							week_day: [],
							time: {},
							end_time: {},
							schedules: [
								{
									week_day: "",
									time: "",
									end_time: "",
								},
							],
							// Set the trimmed name and keep other fields
							name: trimmedName,
							type: prevData.type,
							start_date: prevData.start_date,
						};
					});
				} else {
					if (
						data.errors &&
						data.errors.name &&
						data.errors.name.includes("The name has already been taken.")
					) {
						setErrors((prevErrors) => ({
							...prevErrors,
							name: "Bu qrup adı artıq istifadə olunur.",
						}));
					} else {
						// console.error("Error adding group:", data.message);
					}
				}
			} catch (error) {
				// console.error("Request failed:", error);
			}
		}
	};

	return (
		<Modal show={show} onHide={handleCloseModal}>
			<Modal.Header closeButton>
				<div className="modal-title mb-3">
					<span>Yeni qrup məlumatları</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formName">
								<Form.Label>Qrupun adı</Form.Label>
								<Form.Control
									type="text"
									name="name"
									value={formData.name}
									onChange={handleChange}
									placeholder="Qrupun adını daxil edin"
									isInvalid={!!errors.name}
									className={errors.name ? "input-error" : ""}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formSelect" className="select-form">
								<Form.Label>Fənn</Form.Label>
								<CustomSelect
									options={getData?.map(subject => ({
										value: subject.id,
										label: subject.translations[0].name,
									}))}
									value={
										formData.subject_id
											? { value: formData.subject_id, label: getData.find(subject => subject.id === formData.subject_id)?.translations[0].name || "" }
											: null
									}
									onChange={(selected) => handleChange({ name: 'subject_id', value: selected ? selected.value : "" })}
									placeholder="İstiqaməti seçin"
									isInvalid={!!errors.subject_id}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.subject_id}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formType" className="select-form">
								<Form.Label>Tədrisin təşkili</Form.Label>
								<CustomSelect
									options={[
										{ value: 'group', label: 'Qrup' },
										{ value: 'individual', label: 'Fərdi' },
									]}
									value={
										formData.type
											? { value: formData.type, label: formData.type === 'group' ? 'Qrup' : 'Fərdi' }
											: null
									}
									onChange={(selected) => handleChange({ name: 'type', value: selected ? selected.value : '' })}
									isInvalid={!!errors.type}
									placeholder="Siyahıdan seçin"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.type}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>

						<Col>
							<Form.Group controlId="formStartDate" className="select-form">
								<Form.Label>Başlanma tarixi</Form.Label>
								<Form.Control
									type="date"
									name="start_date"
									value={formData.start_date}
									onChange={handleChange}
									isInvalid={!!errors.start_date}
									className={`pr-5 ${errors.start_date ? "input-error" : ""}`}
								/>

								<Form.Control.Feedback type="invalid">
									{errors.start_date}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="mb-4">
						<Col>
							<Form.Group controlId="formTeacher" className="select-form">
								<Form.Label>Müəllimi seçin</Form.Label>
								<CustomSelect
									options={teachers?.map(teacher => ({ value: teacher.id, label: teacher.full_name }))}
									value={
										formData.teacher_id
											? { value: formData.teacher_id, label: teachers.find(teacher => teacher.id === formData.teacher_id)?.full_name || "" }
											: null
									}
									onChange={(selected) => handleChange({ name: 'teacher_id', value: selected ? selected.value : "" })}
									isInvalid={!!errors.teacher_id}
									placeholder="Seçin..."
								/>
								<Form.Control.Feedback type="invalid">
									{errors.teacher_id}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
					</Row>

					<Row className="schedule mb-4">
						<Col>
							<Form.Group
								controlId="formCheckbox"
								className="custom-checkbox-wrapper"
							>
								<Form.Label>Həftənin günləri</Form.Label>
								<div className="d-flex flex-wrap custom-checkbox-row">
									{["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."].map(
										(label, index) => (
											<div className="custom-checkbox" key={index}>
												<input
													type="checkbox"
													id={`checkbox-${index}`}
													value={label}
													checked={formData.week_day.includes(label)}
													onChange={handleCheckboxChange}
												/>
												<label
													className="checkbox-label"
													htmlFor={`checkbox-${index}`}
												>
													{label}
												</label>
											</div>
										)
									)}
								</div>
							</Form.Group>
						</Col>
					</Row>

					<Row className="time-checkboxes">
						<Col>
							<Form.Group
								controlId="formTimeCheckbox"
								className="custom-checkbox-wrapper"
							>
								<Form.Label>Başlama zamanı</Form.Label>
								<div className="d-flex flex-wrap custom-checkbox-row">
									{["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."].map(
										(label, index) => (
											<div
												className={`custom-checkbox ${formData.week_day.includes(label) ? "active" : ""
													}`}
												key={index}
											>
												<input
													type="checkbox"
													id={`time-checkbox-${index}`}
													checked={!!formData.time[label]}
													onChange={(e) => handleCheckboxChange(e)}
													disabled={!formData.week_day.includes(label)}
												/>
												<label
													className="checkbox-label no-border-label"
													htmlFor={`time-checkbox-${index}`}
												>
													<InputMask
														mask="99:99"
														value={
															formData.week_day.includes(label)
																? formData.time[label] || ""
																: ""
														}
														onChange={(e) => handleTimeInputChange(e, label)}
														onBlur={(e) => handleTimeInputChange(e, label)}
														disabled={!formData.week_day.includes(label)}
														placeholder="00:00"
														className={`time-input ${errors.time?.[label] ? "input-error" : ""
															}`}
													/>
												</label>
											</div>
										)
									)}
								</div>
							</Form.Group>
						</Col>
					</Row>

					<Row className="end-time-checkboxes">
						<Col>
							<Form.Group
								controlId="formTimeCheckbox"
								className="custom-checkbox-wrapper"
							>
								<Form.Label>Bitmə zamanı</Form.Label>
								<div className="d-flex flex-wrap custom-checkbox-row">
									{["B.e.", "Ç.a.", "Ç.", "C.a.", "C.", "Ş.", "B."].map(
										(label, index) => (
											<div
												className={`custom-checkbox ${formData.week_day.includes(label) ? "active" : ""
													}`}
												key={index}
											>
												<input
													type="checkbox"
													id={`time-checkbox-${index}`}
													checked={!!formData.end_time[label]}
													onChange={(e) => handleCheckboxChange(e)}
													disabled={!formData.week_day.includes(label)}
												/>
												<label
													className="checkbox-label no-border-label"
													htmlFor={`time-checkbox-${index}`}
												>
													<InputMask
														mask="99:99"
														value={
															formData.week_day.includes(label)
																? formData.end_time[label] || ""
																: ""
														}
														onChange={(e) => handleTimeInputChange(e, label, true)} // Pass true for end_time
														onBlur={(e) => handleTimeInputChange(e, label, true)}
														disabled={!formData.week_day.includes(label)}
														placeholder="00:00"
														className={`time-input ${errors.end_time?.[label] ? "input-error" : ""
															}`}
													/>
												</label>
											</div>
										)
									)}
								</div>
							</Form.Group>
						</Col>
					</Row>


					<Row>
						<Col className="mt-3 error-form">
							{errors.week_day && (
								<div className="text-danger">{errors.week_day}</div>
							)}
							{Object.keys(errors)
								.filter(
									(key) => key !== "week_day" && formData.week_day.includes(key)
								)
								.map((key) => (
									<div key={key} className="text-danger">
										{errors[key]}
									</div>
								))}
						</Col>
					</Row>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex w-100" style={{ gap: "5px" }}>
					<Button className="modal-btn btn-green" onClick={handleSubmit}>
						Qrupu əlavə et
					</Button>
					<Button className="modal-btn btn-green" onClick={handleLessonSubmit}>
						Dərs əlavə et
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddGroup;
